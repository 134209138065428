// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

// font weight
// Fonts
// Body
// Paragraphs
// Grid columns
// Buttons
// submit button


//
// Color system
//
// stylelint-disable
$gray:     #ccc;
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;//
$gray-600: #f8f9fb; //
$gray-700: #4a4a4a; //
$gray-800: #626375; //
$gray-900: #494a5b; //
$black:    #000;

$grays: ();
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$blue:    #05006d; //
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #03a94a; //
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: ();
$colors: map-merge((
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
), $colors);

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: ();
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
), $theme-colors);


$min-width-container:         320px;
$max-width-container:         940px;
$container-max-width:         940px;

$grid-gutter-width:           2rem;


// Fonts
$font-family-sans-serif:       'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-family-base:            'soleil', $font-family-sans-serif;
$font-ico:                    'icomoon';

$font-size-base:              0.875rem;
$line-height-base:            1.6;

$input-font-family:           $font-family-base;
$input-font-size:             $font-size-base;


/*Typografy*/
$h1-font-size:               2.125rem;
$h2-font-size:               1.875rem;
$h3-font-size:               1.563rem;
$h4-font-size:               1.25rem;
$h5-font-size:               1rem;
$h6-font-size:               $font-size-base;

$headings-font-weight:        400 !default;

$headings-margin-bottom:     1rem;
$headings-line-height:       1.4;

$link-hover-color:           #1c7320;
// Navbar
$navbar-light-color:            $blue;
$navbar-light-active-color:     $blue;
$navbar-light-hover-color:      $blue;
$navbar-icon-color:             $green;
$navbar-padding-y:              0;
$navbar-padding-x:              23px;

// Dropdowns
$dropdown-item-padding-y:           0 !default;
$dropdown-item-padding-x:           0 !default;

// Links
//
// Style anchor elements.

$link-color:                   $green;

// font weight
$font-thin:                   100;
$font-light:                  300;
$font-normal:                 400;
$font-medium:                 500;
$font-semibold:               600;
$font-bold:                   700;
$font-black:                  800;

$widget-margin:               25px;
$vertical-rhythm:             20px;

$wp-caption-border:           1px solid $black;

// $base-min-width:              320px;
// $max-width-center-container:  1200px;

$border-radius-base:          0;
$border-radius:               .25rem !default;


// Body
$body-bg:                     $white;
$body-color:                  $gray-900;

// Paragraphs
$paragraph-margin-bottom:     1rem;

// Grid columns
$grid-columns:                12;
$grid-gutter-width:           30px;

// Buttons
$btn-font-weight:             600;
$btn-border-width:            1px;
$btn-default-color:           #333;
$btn-default-bg:              #fff;
$btn-default-border:          #ccc;
$btn-border-radius:           0;
$btn-padding:                 12px 28px;
$btn-font-size-base:          14px;
$btn-line-height:             $btn-font-size-base + 4;
$btn-padding-x:               28px;
$btn-padding-y:               12px;

// submit button
$submit-btn-padding:          $btn-padding;
$submit-btn-default-color:    $white;
$submit-btn-font-size-base:   $btn-font-size-base;
$submit-btn-font-weight:      $btn-font-weight;
$submit-btn-line-height:      $btn-line-height;
$submit-btn-border-radius:    4px;
$submit-btn-border-width:     $btn-border-width;
$submit-btn-default-border:   $green;
$submit-btn-default-bg:       $green;

/*hr*/
$hr-border-color:             #e8eaf1;
$hr-border-width:             2px;

// Forms

$input-color:                 $gray-800 !default;
$input-btn-padding-y:         .625rem !default;
$input-btn-padding-x:         rem-calc(19) !default;

$input-placeholder-color:      $gray-800 !default;
$input-height-border:          1px ;
$input-height:                 2.5rem; // 40px


/* card */

$card-spacer-y:                     0 !default;
$card-spacer-x:                     0 !default;
$card-border-width:                 0 !default;
$card-border-radius:                0 !default;
$card-border-color:                 rgba($black, .125) !default;
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:                       transparent !default;
$card-bg:                           $white !default;

$card-img-overlay-padding:          1.25rem !default;

$card-group-margin:                 ($grid-gutter-width / 2) !default;
$card-deck-margin:                  $card-group-margin !default;

$card-columns-count:                3 !default;
$card-columns-gap:                  1.25rem !default;
$card-columns-margin:               $card-spacer-y !default;


// Tooltips
//

$tooltip-font-size:           .625rem !default;
$tooltip-max-width:           206px !default;
$tooltip-color:               $gray-900 !default;
$tooltip-bg:                  $white !default;
$tooltip-border-radius:       4px !default;
$tooltip-opacity:             1 !default;
$tooltip-padding-y:           .25rem !default;
$tooltip-padding-x:           .5rem !default;
$tooltip-margin:              0 !default;

$tooltip-arrow-width:         .8rem !default;
$tooltip-arrow-height:        .4rem !default;
$tooltip-arrow-color:         $tooltip-bg !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   0.6625rem;
$alert-padding-x:                   0.7625rem;
$alert-margin-bottom:               1.4rem;
$alert-border-radius:               4px;
$alert-link-font-weight:            $font-bold;
$alert-border-width:                1px;

$alert-bg-level:                    0;
$alert-border-level:                -9;
$alert-color-level:                 6;


//
$border-width:                1px;


/* badge */
$badge-font-size:             0.625rem;
$badge-font-weight:           400;
$badge-padding-y:             2px;
$badge-padding-x:             7px;
$badge-border-radius:         1rem;

$breadcrumb-bg:               transparent;
$breadcrumb-padding-y:        0;
$breadcrumb-padding-x:        0;
$breadcrumb-margin-bottom:    1.4rem;
$breadcrumb-divider:          '\e902';
$breadcrumb-active-color:     #999;
$breadcrumb-divider-color:    #999;

/* table */
$table-border-color:          #e8eaf1;
$table-border-width:          1px;
$table-cell-padding:          1.5rem 0.65rem;

/* mark */
$mark-padding:                0.1rem 0.3rem;
$mark-bg:                     #f7f9fb;

/* code */
$code-font-size:              0.9rem;
$code-color:                  $white;

$pre-color:                   #333;
